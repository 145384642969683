import { platform } from './platform'

class Native {
  adapter = new platform.adapter();
  constructor() {
    // this.version = "";
    // this.client = platform.name;
  }
  login(param) {
    return this.adapter.login(param);
  }
}

/* eslint-disable */
/* @ts-ignore */
const native = (window.native = new Native());
export default native;
