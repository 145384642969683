import { appUser } from 'syttunnel'
import { baseUrl } from '@/utils/basics' 
export default class SYAdapter {
  // constructor() { }
  login(param) {
    // 直接拿接口域名
    const path = baseUrl
    appUser
      .getUserInfo({ needLogin: true, domain: path })
      .then((res) => {
        console.log(res, '密文', param)
      })
      .catch();
  }
}
