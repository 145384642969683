import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'official-website',
    meta: { title: '首页' },
    component: async() => await import(/* webpackChunkName: "home" */ '../views/official-website/index.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    return { top: 0, left: 0 }
  },
  routes
})

export default router
