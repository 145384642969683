/* eslint-disable */
/* @ts-ignore */
const AdapterRequire = (adapter) => require('./' + adapter).default
const userAgent = navigator.userAgent

class Platform {
  constructor(item) {
    const { is, adapter, name } = item;
    Object.assign(this, item, {
      is: is.test ? () => is.test(userAgent) : is,
      adapter: AdapterRequire(adapter || 'm-adapter'),
      name,
    });
  }
}

// 顺丰速运app
export const sy = new Platform({
  is: () =>
    /SFEXPRESSAPP-iOS-ML/g.test(userAgent) ||
    /SFEXPRESSAPP-Android-ML/g.test(userAgent),
  adapter: "sy-adapter",
  name: "sy",
});

// 顺丰丰声app
export const fs = new Platform({
  is: () => /sf-service-mobile/g.test(userAgent),
  adapter: "fs-adapter",
  name: "fs",
});

// // 微信小程序
// export const wxhs = new Platform({
//   is: () => /MicroMessenger/g.test(userAgent),
//   adapter: "WXHSAdapter",
//   name: "wxhs",
// });

// // 支付宝小程序
// export const alihs = new Platform({
//   is: () => /AlipayClient/g.test(userAgent),
//   adapter: "AliHSAdapter",
//   name: "alihs",
// });

// h5页面
export const m = new Platform({
  is: () => false,
  adapter: "m-adapter",
  name: "m",
});
