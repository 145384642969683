import { loadJS } from '../utils'

export default class SYAdapter {
  constructor() {
    loadJS('cordova.js', () => {
      console.log('加载 cordovaJS')
    })
  }
  login(param) {
    document.addEventListener('deviceready', function () {
      //  可以开始调用客户端插件能力了
      try {
        /* eslint-disable */
        /* @ts-ignore */
        if (SFNativeUser) {
          var params = {
            callback: (res) => {
              console.log(res, '丰声-登录', param)
              const { mobile, userName } = res.data || {}
            }
          };
          /* eslint-disable */
          /* @ts-ignore */
          SFNativeUser.getInfo(params);
        }
      } catch (err) { }
    }, false);
  }
}
