// 动态加载文件
export const loadJS = (url, cb) => {
  const head = window.document.getElementsByTagName("head")[0];
  const js = window.document.createElement("script");
  js.setAttribute("type", "text/javascript");
  js.setAttribute("async", "async");
  js.setAttribute("src", url);
  js.onload = cb;
  head.appendChild(js);
};

// 获取路由参数
export const getUrlParams = (url) => {
  url = url || window.location.href;
  if (url.indexOf("?") < 0) return {};
  return url
    .replace(/^.+?\?/, "")
    .replace(/#.+/, "")
    .split("&")
    .filter((param) => param)
    .map(decodeURIComponent)
    .reduce((obj, param) => {
      const i = param.indexOf("=");
      const t = [param.slice(0, i), param.slice(i + 1)];
      obj[t[0]] = t[1];
      return obj;
    }, {});
};
