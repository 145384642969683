<template>
  <section id="app">
    <template v-if="$route.meta.keepAlive">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </template>
    <template v-else>
      <router-view></router-view>
    </template>
  </section>
</template>

<style lang="scss">
@import '@/assets/style/common.scss'
</style>
